@use "../../../../node_modules/@amzn/awsui-design-tokens/polaris" as awsui;

.header {
  padding: unset;
  background-image: url("../../image/LandingPageBackground.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.awsui-polaris-dark-mode {
  .header {
    background-color: awsui.$color-background-home-header;
  }
}

.title {
  color: awsui.$color-text-home-header-default;
}

.category,
.description {
  color: white;
}